<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="旧密码" prop="password">
                    <el-input v-model="ruleForm.password" placeholder="请输入旧密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input v-model="ruleForm.newPassword" placeholder="请输入新密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="重复新密码" prop="newPassword1">
                    <el-input v-model="ruleForm.newPassword1" placeholder="请重复输入旧密码" type="password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="submitForm('ruleForm')">修改密码</el-button>
                    <el-button @click="back" size="small">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";
    export default {
        name: "upPwd",
        components:{
            commonTitle
        },
        data() {
            var chePwd = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请重复输入密码!'));
                } else if (value !== this.ruleForm.newPassword) {
                    return callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            }
            return {
                ruleForm: {
                    ownerId: '',
                    password: '',
                    newPassword: '',
                    newPassword1: ''
                }//校验规则
                , rules: {
                    password: [
                        {required: true, message: '请输入原密码', trigger: 'change'}
                    ],
                    newPassword: [
                        {required: true, message: '请输入新密码', trigger: 'change'}
                    ],
                    newPassword1: [
                        {required: true, trigger: 'change', validator: chePwd}

                    ]
                },
                url: this.$Config.base_server
            };
        }, methods: {
            submitForm(formName) {
                var _this = this;
                //修改密码按钮
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        $.ajax({
                            url: _this.url + "/api-public/opeOwner/updatePass?access_token=" + localStorage.getItem("token"),
                            data: JSON.stringify(_this.ruleForm),
                            type: 'post',
                            contentType: 'application/json',
                            success: function (result) {
                                //判断状态 0 修改成功 1修改失败
                                if (result.resp_code == 0) {
                                    _this.$message({
                                        message: result.resp_msg,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {
                                            _this.$router.push({name: '/'});
                                        }
                                    });
                                } else {
                                    _this.$message({
                                        message: result.resp_msg,
                                        type: 'error',
                                        duration: 1000,
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            back() {
                window.history.back(-1);
            }
        }
    }
</script>

<style scoped>

    .el-form{
        width: 600px;
        margin: 0 auto;
    }
</style>